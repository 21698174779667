import React from 'react'
import {Button, ButtonProps, useWalletModal} from '@pancakeswap-libs/uikit'
import useAuth from 'hooks/useAuth'
import {useTranslation} from "react-i18next";

const UnlockButton: React.FC<ButtonProps> = (props) => {
    const {t} = useTranslation()
    const {login, logout} = useAuth()
    const {onPresentConnectModal} = useWalletModal(login, logout)

    return (
        <Button onClick={onPresentConnectModal} {...props}>
            {t('Connect Wallet')}
        </Button>
    )
}

export default UnlockButton
