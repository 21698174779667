// export const EN = { code: 'en', language: 'English' }
// export const AR = { code: 'ar', language: 'العربية' }
// export const BN = { code: 'bn', language: 'বাংলা'}
// export const ZHCN = { code: 'zh-CN', language: '简体中文' }
// export const ZHTW = { code: 'zh-TW', language: '繁體中文' }
// export const NL = { code: 'nl', language: 'Nederlands' }
// export const FIL = { code: 'fil', language: 'Filipino' }
// export const FI = { code: 'fi', language: 'Suomalainen' }
// export const FR = { code: 'fr', language: 'Français' }
// export const DE = { code: 'de', language: 'Deutsch' }
// export const EL = { code: 'el', language: 'Ελληνικά' }
// export const HI = { code: 'hi', language: 'हिंदी' }
// export const HU = { code: 'hu', language: 'Magyar' }
// export const ID = { code: 'id', language: 'Bahasa Indonesia' }
// export const IT = { code: 'it', language: 'Italiano' }
// export const JA = { code: 'ja', language: '日本語' }
// export const KO = { code: 'ko', language: '한국어' }
// export const PL = { code: 'pl', language: 'Polski' }
// export const PTPT = { code: 'pt-PT', language: 'Português (Portugal)' }
// export const PTBR = { code: 'pt-BR', language: 'Português (Brasil)' }
// export const RO = { code: 'ro', language: 'Română' }
// export const RU = { code: 'ru', language: 'Русский' }
// export const ESES = { code: 'es-ES', language: 'Español' }
// export const SVSE = { code: 'sv-SE', language: 'Svenska' }
// export const TA = { code: 'ta', language: 'தமிழ்' }
// export const TR = { code: 'tr', language: 'Türkçe' }
// export const UK = { code: 'uk', language: 'Українська' }
// export const VI = { code: 'vi', language: 'Tiếng Việt' }
//
// export const allLanguages = [
//   EN,
//   ZHCN
// ]

export const AR = { locale: 'ar-SA', language: 'العربية', code: 'ar' }
export const BN = { locale: 'bn-BD', language: 'বাংলা', code: 'bn' }
export const EN = { locale: 'en-US', language: 'English', code: 'en' }
export const DE = { locale: 'de-DE', language: 'Deutsch', code: 'de' }
export const EL = { locale: 'el-GR', language: 'Ελληνικά', code: 'el' }
export const ESES = { locale: 'es-ES', language: 'Español', code: 'es-ES' }
export const FI = { locale: 'fi-FI', language: 'Suomalainen', code: 'fi' }
export const FIL = { locale: 'fil-PH', language: 'Filipino', code: 'fil' }
export const FR = { locale: 'fr-FR', language: 'Français', code: 'fr' }
export const HI = { locale: 'hi-IN', language: 'हिंदी', code: 'hi' }
export const HU = { locale: 'hu-HU', language: 'Magyar', code: 'hu' }
export const ID = { locale: 'id-ID', language: 'Bahasa Indonesia', code: 'id' }
export const IT = { locale: 'it-IT', language: 'Italiano', code: 'it' }
export const JA = { locale: 'ja-JP', language: '日本語', code: 'ja' }
export const KO = { locale: 'ko-KR', language: '한국어', code: 'ko' }
export const NL = { locale: 'nl-NL', language: 'Nederlands', code: 'nl' }
export const PL = { locale: 'pl-PL', language: 'Polski', code: 'pl' }
export const PTBR = { locale: 'pt-BR', language: 'Português (Brazil)', code: 'pt-br' }
export const PTPT = { locale: 'pt-PT', language: 'Português', code: 'pt-pt' }
export const RO = { locale: 'ro-RO', language: 'Română', code: 'ro' }
export const RU = { locale: 'ru-RU', language: 'Русский', code: 'ru' }
export const SVSE = { locale: 'sv-SE', language: 'Svenska', code: 'sv' }
export const TA = { locale: 'ta-IN', language: 'தமிழ்', code: 'ta' }
export const TR = { locale: 'tr-TR', language: 'Türkçe', code: 'tr' }
export const UK = { locale: 'uk-UA', language: 'Українська', code: 'uk' }
export const VI = { locale: 'vi-VN', language: 'Tiếng Việt', code: 'vi' }
export const ZHCN = { locale: 'zh-CN', language: '简体中文', code: 'zh-cn' }
export const ZHTW = { locale: 'zh-TW', language: '繁體中文', code: 'zh-tw' }

export const languages= {
  'ar-SA': AR,
  'bn-BD': BN,
  'en-US': EN,
  'de-DE': DE,
  'el-GR': EL,
  'es-ES': ESES,
  'fi-FI': FI,
  'fil-PH': FIL,
  'fr-FR': FR,
  'hi-IN': HI,
  'hu-HU': HU,
  'id-ID': ID,
  'it-IT': IT,
  'ja-JP': JA,
  'ko-KR': KO,
  'nl-NL': NL,
  'pl-PL': PL,
  'pt-BR': PTBR,
  'pt-PT': PTPT,
  'ro-RO': RO,
  'ru-RU': RU,
  'sv-SE': SVSE,
  'ta-IN': TA,
  'tr-TR': TR,
  'uk-UA': UK,
  'vi-VN': VI,
  'zh-CN': ZHCN,
  'zh-TW': ZHTW,
}

export const allLanguages = Object.values(languages)

