import React from 'react'
import { Modal } from '@pancakeswap-libs/uikit'
import SlippageToleranceSetting from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'
import AudioSetting from './AudioSetting'

type SettingsModalProps = {
  onDismiss?: () => void,
    t: (s: string) => string
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal = ({ onDismiss = defaultOnDismiss,t }: SettingsModalProps) => {
  return (
    <Modal title={t( 'Settings')} onDismiss={onDismiss}>
      <SlippageToleranceSetting t={t} />
      <TransactionDeadlineSetting t={t} />
      <AudioSetting t={t} />
    </Modal>
  )
}

export default SettingsModal
