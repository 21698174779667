import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import * as process from "process";

i18next
    .use(XHR)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        backend: {
            loadPath: `./locales/{{lng}}.json`
        },
        react: {
            useSuspense: true
        },
        fallbackLng: 'en',
        preload: ['en'],
        keySeparator: '-',
        interpolation: {escapeValue: false},
        debug: process.env.NODE_ENV === 'development',
        detection: {
            // convertDetectedLanguage: (lng) => {
            //     lng.replace('-', '_')
            // }
        }
        // convertDetectedLanguage: 'Iso15897',
        // convertDetectedLanguage: (lng) => lng.replace('-', '_')
    })

export default i18next
