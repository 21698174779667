import {useCallback} from 'react'
import {useWeb3React, UnsupportedChainIdError} from '@web3-react/core'
import {NoBscProviderError} from '@binance-chain/bsc-connector'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import {connectorLocalStorageKey, ConnectorNames} from '@pancakeswap-libs/uikit'
import useToast from 'hooks/useToast'
import {connectorsByName} from 'connectors'
import {useTranslation} from "react-i18next";

const useAuth = () => {
    const {activate, deactivate} = useWeb3React()
    const {toastError} = useToast()
    const {t} = useTranslation();
    const login = useCallback((connectorID: ConnectorNames) => {
        const connector = connectorsByName[connectorID]
        if (connector) {
            activate(connector, async (error: Error) => {
                window.localStorage.removeItem(connectorLocalStorageKey)
                if (error instanceof UnsupportedChainIdError) {
                    toastError(t('Error'), t('You’re connected to the wrong network.'))
                } else if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
                    toastError(t('Error'), t('no provider found'))
                } else if (
                    error instanceof UserRejectedRequestErrorInjected ||
                    error instanceof UserRejectedRequestErrorWalletConnect
                ) {
                    if (connector instanceof WalletConnectConnector) {
                        const walletConnector = connector as WalletConnectConnector
                        walletConnector.walletConnectProvider = null
                    }
                    toastError(t('Error'), t('Error connecting, please authorize wallet to access.'))
                } else {
                    toastError(error.name, error.message)
                }
            })
        } else {
            toastError("Can't find connector", 'The connector config is wrong')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {login, logout: deactivate}
}

export default useAuth
