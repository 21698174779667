import React, {useContext, useMemo} from 'react'
import {ThemeContext} from 'styled-components'
import {Pair} from '@pancakeswap-libs/sdk'
import {Button, CardBody, Text} from '@pancakeswap-libs/uikit'
import {Link} from 'react-router-dom'
import CardNav from 'components/CardNav'
import FullPositionCard from 'components/PositionCard'
import {useTokenBalancesWithLoadingIndicator} from 'state/wallet/hooks'
import {StyledInternalLink} from 'components/Shared'
import {LightCard} from 'components/Card'
import {RowBetween} from 'components/Row'
import {AutoColumn} from 'components/Column'

import {useActiveWeb3React} from 'hooks'
import {usePairs} from 'data/Reserves'
import {toV2LiquidityToken, useTrackedTokenPairs} from 'state/user/hooks'
import {Dots} from 'components/swap/styleds'
import {useTranslation} from 'react-i18next'
import PageHeader from 'components/PageHeader'
import AppBody from '../AppBody'

export default function Pool() {
    const theme = useContext(ThemeContext)
    const {account} = useActiveWeb3React()
    const {t} = useTranslation()

    // fetch the user's balances of all tracked V2 LP tokens
    const trackedTokenPairs = useTrackedTokenPairs()
    const tokenPairsWithLiquidityTokens = useMemo(
        () => trackedTokenPairs.map((tokens) => ({liquidityToken: toV2LiquidityToken(tokens), tokens})),
        [trackedTokenPairs]
    )
    const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
        tokenPairsWithLiquidityTokens,
    ])
    const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
        account ?? undefined,
        liquidityTokens
    )

    // fetch the reserves for all V2 pools in which the user has a balance
    const liquidityTokensWithBalances = useMemo(
        () =>
            tokenPairsWithLiquidityTokens.filter(({liquidityToken}) =>
                v2PairsBalances[liquidityToken.address]?.greaterThan('0')
            ),
        [tokenPairsWithLiquidityTokens, v2PairsBalances]
    )

    const v2Pairs = usePairs(liquidityTokensWithBalances.map(({tokens}) => tokens))
    const v2IsLoading =
        fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

    const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

    return (
        <>
            <CardNav activeIndex={1}/>
            <AppBody>
                <PageHeader
                    title={t('Liquidity')}
                    description={t('List of your liquidity positions')}
                >
                    <Button id="join-pool-button" as={Link} to="/add/PHIC">
                        {t('Add Liquidity')}
                    </Button>
                </PageHeader>
                <AutoColumn gap="lg" justify="center">
                    <CardBody>
                        <AutoColumn gap="12px" style={{width: '100%'}}>
                            <RowBetween padding="0 8px">
                                <Text color={theme.colors.text}>{t('Your Liquidity')}</Text>
                                {/* <Question */}
                                {/*     text={t( */}
                                {/*         'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.' */}
                                {/*     )} */}
                                {/* /> */}
                            </RowBetween>

                            {!account ? (
                                <LightCard padding="40px">
                                    <Text color="textDisabled" textAlign="center">
                                        {t('Connect to a wallet to view your liquidity.')}
                                    </Text>
                                </LightCard>
                            ) : v2IsLoading ? (
                                <LightCard padding="40px">
                                    <Text color="textDisabled" textAlign="center">
                                        <Dots>Loading</Dots>
                                    </Text>
                                </LightCard>
                            ) : allV2PairsWithLiquidity?.length > 0 ? (
                                <>
                                    {allV2PairsWithLiquidity.map((v2Pair) => (
                                        <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair}/>
                                    ))}
                                </>
                            ) : (
                                <LightCard padding="40px">
                                    <Text color="textDisabled" textAlign="center">
                                        {t('No liquidity found.')}
                                    </Text>
                                </LightCard>
                            )}

                            <div style={{width:'100%'}}>
                                <Text fontSize="14px" style={{padding: '.5rem 0 .5rem 0',width:'100%'}}>
                                    {t("Don't see a pair you joined?")}{' '}
                                    <StyledInternalLink id="import-pool-link" to="/find">
                                        {t('Find other LP tokens')}
                                    </StyledInternalLink>
                                </Text>
                                {/* <Text fontSize="14px" style={{padding: '.5rem 0 .5rem 0'}}> */}
                                {/*     {t('Or, if you staked your LP tokens in a farm, unstake them to see them here.')} */}
                                {/* </Text> */}
                            </div>
                        </AutoColumn>
                    </CardBody>
                </AutoColumn>
            </AppBody>
        </>
    )
}
