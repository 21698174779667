import React from 'react'
import {Box, Flex, Text, Toggle, useMatchBreakpoints} from '@pancakeswap-libs/uikit'
import {useAudioModeManager} from 'state/user/hooks'

type AudioSettingModalProps = {
  t: (s: string) => string
}

const AudioSetting = ({ t }: AudioSettingModalProps) => {
  const { isSm, isXs } = useMatchBreakpoints()
  const [audioPlay, toggleSetAudioMode] = useAudioModeManager()

  return (
    <Box mb="16px">
      <Flex alignItems="center" mb="8px">
        <Text bold>{t( 'Flippy sounds')}</Text>
      </Flex>
      <Box>
        <Toggle scale={isSm || isXs ? 'sm' : 'md'} checked={audioPlay} onChange={toggleSetAudioMode} />
      </Box>
    </Box>
  )
}

export default AudioSetting
